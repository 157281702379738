import React from 'react';
import { graphql } from 'gatsby';
import CraftPageBuilder from '~/components/craft/CraftPageBuilder';
import Seo from '~/components/common/Seo';
import { Query, Craft_Calculator } from '~/query';

type TemplatePageProps = {
  data: Query;
};

/**
 * Template: Calculator
 *
 * Use this template in correspondence with a craft
 * section with the following properties:
 *
 * Type: single
 * Name: calculator
 * URI: calculator
 */
export default function TemplatePages({ data }: TemplatePageProps) {
  const { pageBuilder, title, seoTitle, seoDescription, seoCanonical } = (data?.craft
    ?.entry ?? {}) as Craft_Calculator;

  return (
    <>
      <Seo
        title={seoTitle || title}
        description={seoDescription || undefined}
        canonical={seoCanonical ?? undefined}
      />
      <CraftPageBuilder components={pageBuilder} />
    </>
  );
}

export const query = graphql`
  query QueryCalculatorPage($id: [Int]!, $site: String!) {
    craft {
      entry(id: $id, site: $site) {
        id
        title
        ... on Craft_Calculator {
          pageBuilder {
            ...CraftPageBuilderQuery
          }

          # SEO
          seoTitle
          seoDescription
        }
      }
    }
  }
`;
